import React from 'react';

interface Props {
  name: string | undefined;
}

export default function FloatingReferral({ name }: Props) {
  if (!name) return null;

  return (
    <div className="absolute -top-3 -right-3 rotate-12">
      <div className="flex animate-float overflow-hidden rounded-full dark:bg-gray-800">
        <div className="flex w-full origin-center items-center gap-3 bg-green-400/20 py-3.5 pl-4 pr-9 text-green-600 shadow-lg ring-1 ring-inset ring-green-500/5 dark:bg-green-300/10 dark:text-green-200">
          <img
            src="/assets/login/dennis-memoji.png"
            alt="Inviter profile"
            className="h-12 w-12 rounded-full shadow-md"
          />

          <div className="flex flex-col">
            <p className="text-sm font-semibold opacity-70">Hey {name},</p>
            <h2 className="font-semibold">You&apos;re invited to drop #1.</h2>
          </div>
        </div>
      </div>
      {/* <div className="absolute left-20 -bottom-11 text-green-500 dark:text-green-300/30">
        <svg
          width="95"
          height="36"
          viewBox="0 0 95 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M27.2163 27.7646C27.2163 27.7646 27.4526 14.8583 36.5 14.5C42.5533 14.2603 42.1913 19.7649 51.4936 19.7649C59.1913 19.7649 61.4613 5.96074 63.5534 1.96499M63.5534 1.96499C66.808 5.32963 66.5189 11.1068 66.5189 11.1068M63.5534 1.96499C59.3869 1.34684 54.2959 4.54686 54.2959 4.54686"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div> */}
    </div>
  );
}
