import React, { useRef } from 'react';
import DisplayEvent from './DisplayEvent';
import displayEventFixtures, {
  getInsetEventFixtures,
} from './displayEventFixtures';
import { useWindowSize } from 'react-use';
import { AnimatePresence } from 'framer-motion';

function getCoord({
  width,
  height,
  index,
  xPadding = 20,
  yPadding = 20,
}: {
  width: number;
  height: number;
  index: number;
  xPadding?: number;
  yPadding?: number;
}) {
  const isOdd = index % 2;
  const x = width * Math.random();
  const y = height * Math.random();
  const x2 = getRandom([xPadding, width - xPadding]);
  const y2 = getRandom([yPadding, height - yPadding]);

  return { x: isOdd ? x : x2, y: isOdd ? y2 : y };
}
interface Props {
  name: string | undefined;
}

export default React.memo(function DisplayEvents({ name }: Props) {
  const ref = useRef<HTMLDivElement | null>(null);
  const { width, height } = useWindowSize();
  const insetEventFixtures = name ? getInsetEventFixtures(name) : [];

  const coordsRef = useRef(
    displayEventFixtures.map((_, index) => getCoord({ width, height, index }))
  );
  const insetCoordsRef = useRef(
    insetEventFixtures.map((_, index) =>
      getCoord({
        width,
        height,
        index,
        xPadding: Math.max(20, width * 0.12),
        yPadding: Math.max(20, height * 0.12),
      })
    )
  );

  return (
    <div ref={ref} className="pointer-events-none fixed inset-0 z-0 p-4">
      <AnimatePresence>
        {displayEventFixtures.map((event, index) => (
          <DisplayEvent
            parentRef={ref}
            key={index}
            index={index}
            {...coordsRef.current[index]}
            {...event}
          />
        ))}
        {insetEventFixtures.map((event, index) => (
          <DisplayEvent
            parentRef={ref}
            key={`inset-${index}`}
            index={index}
            {...insetCoordsRef.current[index]}
            {...event}
          />
        ))}
      </AnimatePresence>
    </div>
  );
});

function getRandom(items: number[]) {
  return items[Math.floor(Math.random() * items.length)];
}
