import { Theme_Enum } from '@graphql-types@';
import classNames from 'classnames';
import { useThemeContext } from 'contexts/theme';
import Button from 'joy/Button';
import React, { useEffect, useRef, useState } from 'react';
import {
  API_ROUTE_AUTH_START,
  TEST_ID_LOGIN_BUTTON,
} from '../../utils/constants';
import Head from 'next/head';

const ONE_MINUTE = 60_000; // ms

export default React.memo(function ButtonGoogleLogin() {
  const [isPending, setIsPending] = useState(true);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { theme } = useThemeContext();
  const cssThemeClass = theme === Theme_Enum.Dark ? 'light' : 'dark';
  const [authURL, setAuthURL] = useState<string | null>(null);
  const firstFetchTimestamp = useRef(0);

  const refreshAuthURL = async () => {
    setIsPending(true);
    try {
      const res = await fetch(API_ROUTE_AUTH_START, {
        method: 'GET',
        headers: {
          // Cookies are needed along with this request.
          credentials: 'include',
        },
      }).then((res) => res.json());
      if (!res?.url) {
        throw new Error('No url returned...');
      }
      firstFetchTimestamp.current = Date.now();
      setAuthURL(res.url);
      setIsPending(false);
      return res.url;
    } catch (error) {
      console.error(error);
      setIsPending(false);
      return null;
    }
  };

  useEffect(() => {
    refreshAuthURL();
  }, []);

  const login = async () => {
    let redirectUrl = authURL;
    if (Date.now() - firstFetchTimestamp.current > ONE_MINUTE || !redirectUrl) {
      redirectUrl = await refreshAuthURL();
    }
    if (!redirectUrl) {
      return;
    }

    window.location.href = redirectUrl;
  };

  return (
    <>
      <Head>
        <link
          rel="preconnect"
          href="https://accounts.google.com"
          crossOrigin=""
        />
        <link rel="dns-prefetch" href="https://accounts.google.com" />
      </Head>

      <Button
        ref={buttonRef}
        disabled={isPending}
        onClick={login}
        data-testid={TEST_ID_LOGIN_BUTTON}
        className={classNames(
          `flex w-full items-center justify-center rounded-xl bg-gray-800 px-10 py-4 text-gray-100 transition-colors duration-150 hover:bg-gray-700 dark:bg-gray-100 dark:text-gray-800 dark:hover:bg-gray-200`
        )}
      >
        <img
          className="inline w-4 opacity-80"
          src={`/assets/login/google-icon-${cssThemeClass}.svg`}
          alt="google icon"
        />

        <span
          className="ml-2.5 text-base font-semibold"
          style={{ lineBreak: 'anywhere' }}
        >
          Continue with Google
        </span>
      </Button>
    </>
  );
});
