/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { ColorFamily } from '@graphql-types@';
import { motion } from 'framer-motion';
import Checkbox from 'joy/Checkbox';
import React, { useRef, useState } from 'react';
import { colorMap } from 'utils/eventColors/factory';

interface Props {
  title: string;
  time: string;
  x: number | string;
  y: number | string;
  index: number;
  parentRef: React.MutableRefObject<HTMLDivElement | null>;
  style?: React.CSSProperties;
}

export default React.memo(function DisplayEvent({
  title,
  time,
  style,
  x,
  y,
  parentRef,
  index,
}: Props) {
  const [checked, setChecked] = useState(Math.random() > 0.5);
  const opacityRef = useRef(getRandomOpacity());
  const rotateRef = useRef(getRandomAxisRotation());

  return (
    <motion.div
      drag={true}
      dragConstraints={parentRef}
      dragElastic={0.2}
      className="pointer-events-auto absolute flex h-px w-px"
      initial={{ opacity: 0, scale: 0.7 }}
      animate={{ opacity: 1, scale: 1, transition: { delay: index * 0.04 } }}
      style={{
        top: y,
        left: x,
        rotate: rotateRef.current,
        ...style,
      }}
    >
      <div className="absolute flex w-fit origin-center -translate-x-1/2 -translate-y-1/2 scale-100 cursor-grab overflow-hidden rounded-2xl bg-white shadow transition-transform active:scale-98 active:cursor-grabbing dark:bg-gray-800 dark:shadow-feintLg">
        <div
          className="flex bg-gradient-to-t py-3 pr-8 pl-3 dark:from-gray-700 dark:to-gray-600"
          style={{ opacity: opacityRef.current }}
        >
          <Checkbox
            value={checked}
            onChange={() => setChecked(!checked)}
            variant="event"
            colorMap={colorMap[ColorFamily.Gray]}
            className="mt-0.5 self-start"
          />

          <div className="ml-2 -mt-0.5 flex flex-col">
            <p
              spellCheck={false}
              contentEditable={true}
              onClick={() =>
                document.execCommand('selectAll', false, undefined)
              }
              className="min-w-[80px] cursor-text whitespace-nowrap border-none text-base font-medium outline-none selection:bg-pink-400/10 hover:opacity-80 dark:selection:bg-pink-400/10"
            >
              {title}
            </p>
            <p
              spellCheck={false}
              contentEditable={true}
              onClick={() =>
                document.execCommand('selectAll', false, undefined)
              }
              className="text-secondary min-w-[80px] cursor-text border-none text-[13px] font-semibold outline-none selection:bg-pink-400/10 hover:opacity-80 dark:selection:bg-pink-400/10"
            >
              {time}
            </p>
          </div>
        </div>
      </div>
    </motion.div>
  );
});

function getRandomAxisRotation(): number {
  const angle = 25;
  return (Math.random() * 2 - 1) * angle;
}

function getRandomOpacity(): number {
  const minOpacity = 0.4;
  return Math.min(Math.random() + minOpacity, 1);
}
