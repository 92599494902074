import React, { useRef, useState } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { parseCookies } from 'nookies';
import {
  AMIE_PAGE_TITLE,
  COOKIE_NAME_SCOPES,
  GOOGLE_SCOPES_CALENDAR,
} from '../utils/constants';
import ButtonGoogleLogin from '../components/Login/ButtonGoogleLogin';
import LayoutLoggedOut from 'components/LayoutLoggedOut';
import DisplayEvents from 'components/Login/DisplayEvents';
import WordMark from 'components/Wordmark';
import { useEffect } from 'react';
import useMounted from 'hooks/useMounted';
import FloatingReferral from 'components/Login/FloatingReferral';
import { motion } from 'framer-motion';
const title = `Login | ${AMIE_PAGE_TITLE}`;
import IconBell from 'components/Icons/IconBell';

export default function Login() {
  const mounted = useMounted();
  const { prefetch, replace, query } = useRouter();
  const [permissions, setPermissions] = useState<string | undefined>();

  const name = useRef<string | undefined>(query.n as string | undefined);

  useEffect(() => {
    // Prefetch the index page as the user will go there after the login.
    // It has a huge bundle from Firebase libs...
    prefetch('/');
    // Clear the `n` query
    replace('/login', undefined, { shallow: true });
  }, []); // eslint-disable-line

  useEffect(() => {
    const cookies = parseCookies();

    if (cookies[COOKIE_NAME_SCOPES]?.indexOf(GOOGLE_SCOPES_CALENDAR) < 0) {
      setPermissions('calendar');
    }
  }, []);

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="description" content={title} />
      </Head>
      <LayoutLoggedOut>
        {mounted && <DisplayEvents name={name.current} />}

        <div className="pointer-events-none relative z-20 m-auto flex w-full max-w-[520px] flex-col">
          <FloatingReferral name={name.current} />

          <div className="flex w-full flex-col p-12">
            <div className="mb-5 flex">
              <WordMark />
            </div>

            <div className="flex">
              <p className="text-secondary pointer-events-auto text-4xl font-semibold leading-tight">
                Joyful and productive collaboration.&nbsp;
                <span className="text-primary">
                  All in one
                  <a
                    className="hover:text-primary scale-100 transition-colors hover:scale-105"
                    href="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    .
                  </a>
                </span>
              </p>
            </div>

            <div className="pointer-events-auto mt-6 flex flex-col">
              {permissions && (
                <motion.div
                  key="calendar-permissions"
                  className="group pointer-events-auto mb-3 flex w-full cursor-default rounded-xl bg-white p-3 shadow-feintMd dark:bg-gray-700"
                  initial={{ translateY: 8, opacity: 0 }}
                  animate={{ translateY: 0, opacity: 1 }}
                  exit={{ translateY: 8, opacity: 0 }}
                  transition={{ duration: 0.2 }}
                  layout={true}
                >
                  <motion.div
                    key="calendar-permissions-title"
                    layout="position"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2 }}
                    className={'justify-beween flex items-center gap-5'}
                  >
                    <div className="flex">
                      <IconBell className="mr-2 mt-0.5 h-4 w-4 animate-bell " />
                      <div>
                        <p className="text-sm font-semibold">
                          Amie needs access to Google Calendar
                        </p>
                        <small className="text-secondary font-medium">
                          Make sure to tick the box in the permissions step
                        </small>
                      </div>
                    </div>
                  </motion.div>
                </motion.div>
              )}

              <ButtonGoogleLogin />

              <small className="text-secondary mt-3 w-full text-[11px]">
                By clicking “Continue with Google”, you acknowledge that you
                have read and understood, and agree to Amie&apos;s Terms &
                Conditions and Privacy Policy.
              </small>
            </div>
          </div>
        </div>
      </LayoutLoggedOut>
    </>
  );
}
