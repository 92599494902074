interface FakeDisplayEvent {
  title: string;
  time: string;
}

export function getInsetEventFixtures(name: string): FakeDisplayEvent[] {
  return [
    {
      title: `Make pancakes with ${name}`,
      time: '10:00',
    },
    {
      title: `Cinema with ${name}`,
      time: '18:00',
    },
    {
      title: `${name}'s birthday party`,
      time: '16:00',
    },
    {
      title: `${name}'s gaming night`,
      time: '20:00',
    },
  ];
}

const displayEventFixtures: FakeDisplayEvent[] = [
  {
    title: 'Sign up for Amie',
    time: '10:00',
  },
  {
    title: 'Bake a cake',
    time: '18:00',
  },
  {
    title: 'Mountain hike',
    time: '18:00',
  },
  {
    title: 'Go to the gym',
    time: '19:00',
  },
  {
    title: 'Ship new NFT',
    time: '20:00',
  },
  {
    title: 'Write plan for diversity',
    time: '11:00',
  },
  {
    title: 'Buy a new car',
    time: '12:00',
  },
  {
    title: 'Family BBQ',
    time: '14:00',
  },
  {
    title: 'Build something fun',
    time: '08:00',
  },
  {
    title: 'Hang with friends',
    time: '23:00',
  },
  {
    title: 'Meet with Ava',
    time: '16:00',
  },
  {
    title: 'Brunch with Felix',
    time: '10:30',
  },
  {
    title: 'Sign up for Amie',
    time: '10:00',
  },
  {
    title: 'Bake a cake',
    time: '18:00',
  },
  {
    title: 'Mountain hike',
    time: '18:00',
  },
  {
    title: 'Go to the gym',
    time: '19:00',
  },
  {
    title: 'Ship new NFT',
    time: '20:00',
  },
  {
    title: 'Write plan for diversity',
    time: '11:00',
  },
  {
    title: 'Buy a new car',
    time: '12:00',
  },
  {
    title: 'Family BBQ',
    time: '14:00',
  },
  {
    title: 'Build something fun',
    time: '08:00',
  },
  {
    title: 'Hang with friends',
    time: '23:00',
  },
  {
    title: 'Meet with Ava',
    time: '16:00',
  },
  {
    title: 'Brunch with Felix',
    time: '10:30',
  },
  {
    title: 'Sign up for Amie',
    time: '10:00',
  },
  {
    title: 'Bake a cake',
    time: '18:00',
  },
  {
    title: 'Mountain hike',
    time: '18:00',
  },
  {
    title: 'Go to the gym',
    time: '19:00',
  },
  {
    title: 'Ship new NFT',
    time: '20:00',
  },
  {
    title: 'Write plan for diversity',
    time: '11:00',
  },
  {
    title: 'Buy a new car',
    time: '12:00',
  },
  {
    title: 'Family BBQ',
    time: '14:00',
  },
  {
    title: 'Build something fun',
    time: '08:00',
  },
  {
    title: 'Hang with friends',
    time: '23:00',
  },
  {
    title: 'Meet with Ava',
    time: '16:00',
  },
  {
    title: 'Brunch with Felix',
    time: '10:30',
  },
  {
    title: 'Sign up for Amie',
    time: '10:00',
  },
  {
    title: 'Bake a cake',
    time: '18:00',
  },
  {
    title: 'Mountain hike',
    time: '18:00',
  },
  {
    title: 'Go to the gym',
    time: '19:00',
  },
  {
    title: 'Ship new NFT',
    time: '20:00',
  },
  {
    title: 'Write plan for diversity',
    time: '11:00',
  },
  {
    title: 'Buy a new car',
    time: '12:00',
  },
  {
    title: 'Family BBQ',
    time: '14:00',
  },
  {
    title: 'Build something fun',
    time: '08:00',
  },
  {
    title: 'Hang with friends',
    time: '23:00',
  },
  {
    title: 'Meet with Ava',
    time: '16:00',
  },
  {
    title: 'Brunch with Felix',
    time: '10:30',
  },
  {
    title: 'Sign up for Amie',
    time: '10:00',
  },
  {
    title: 'Bake a cake',
    time: '18:00',
  },
  {
    title: 'Mountain hike',
    time: '18:00',
  },
  {
    title: 'Go to the gym',
    time: '19:00',
  },
  {
    title: 'Ship new NFT',
    time: '20:00',
  },
  {
    title: 'Write plan for diversity',
    time: '11:00',
  },
  {
    title: 'Buy a new car',
    time: '12:00',
  },
  {
    title: 'Family BBQ',
    time: '14:00',
  },
  {
    title: 'Build something fun',
    time: '08:00',
  },
  {
    title: 'Hang with friends',
    time: '23:00',
  },
  {
    title: 'Meet with Ava',
    time: '16:00',
  },
  {
    title: 'Brunch with Felix',
    time: '10:30',
  },
  {
    title: 'Sign up for Amie',
    time: '10:00',
  },
  {
    title: 'Bake a cake',
    time: '18:00',
  },
  {
    title: 'Mountain hike',
    time: '18:00',
  },
  {
    title: 'Go to the gym',
    time: '19:00',
  },
  {
    title: 'Ship new NFT',
    time: '20:00',
  },
  {
    title: 'Write plan for diversity',
    time: '11:00',
  },
  {
    title: 'Buy a new car',
    time: '12:00',
  },
  {
    title: 'Family BBQ',
    time: '14:00',
  },
  {
    title: 'Build something fun',
    time: '08:00',
  },
  {
    title: 'Hang with friends',
    time: '23:00',
  },
  {
    title: 'Meet with Ava',
    time: '16:00',
  },
  {
    title: 'Brunch with Felix',
    time: '10:30',
  },
];

export default displayEventFixtures;
